import { Injectable } from '@angular/core';
import { BackendService, backendResponse } from './backend.service';
import { Property } from './properties-backend.service';
import { Task, TaskResult, TaskStatus } from './task-backend.service';
import { Chain } from './chains-backend.service';

@Injectable({
	providedIn: 'root',
})
export class EmailBackendService {
	constructor(public backend: BackendService) {}

	async getList(
		filter: 'All' | 'SENT' | 'RECEIVED' = 'All',
		lastKey?: string
	): Promise<backendResponse<Email[]>> {
		let q = ``;
		if (lastKey) q = `&lastKey=${encodeURIComponent(lastKey)}`;
		return (await this.backend.get(
			`inbox?filter=${filter}${q}`
		)) as backendResponse<Email[]>;
	}

	async get(id: string): Promise<backendResponse<Email>> {
		return (await this.backend.get(`inbox/${id}?`)) as backendResponse<Email>;
	}

	async getAdminList(
		filter: 'ALL' | 'NEEDSREVIEW' | 'REVIEWED' = 'ALL',
		lastKey?: string
	): Promise<backendResponse<Email[]>> {
		let q = ``;
		if (lastKey) q = `&lastKey=${encodeURIComponent(lastKey)}`;
		return (await this.backend.get(
			`admin/inbox?filter=${filter}${q}`
		)) as backendResponse<Email[]>;
	}

	async getAdmin(id: string): Promise<backendResponse<Email>> {
		return (await this.backend.get(
			`admin/inbox/${id}?`
		)) as backendResponse<Email>;
	}

	async getAdminChain(id: string): Promise<backendResponse<Chain>> {
		return (await this.backend.get(
			`admin/chain/${id}?`
		)) as backendResponse<Chain>;
	}

	async getEmailAddress(): Promise<
		backendResponse<
			{
				firstName: string;
				lastName: string;
				userEmail: string;
				ccEmail: string;
				createdAt: string;
				id: string;
			}[]
		>
	> {
		return (await this.backend.get(`emailAddresses`)) as backendResponse<
			{
				id: string;
				firstName: string;
				lastName: string;
				userEmail: string;
				ccEmail: string;
				createdAt: string;
			}[]
		>;
	}

	async getEmailAddressesDomains(): Promise<backendResponse<string[]>> {
		return (await this.backend.get(`emailAddressesDomains`)) as backendResponse<
			string[]
		>;
	}

	async setEmailAddressDefault(data: { id: string }): Promise<
		backendResponse<{
			id: string;
			firstName: string;
			lastName: string;
			userEmail: string;
			ccEmail: string;
			createdAt: string;
		}>
	> {
		return (await this.backend.patch(`emailAddresses/${data.id}`, {
			makeDefault: true,
		})) as backendResponse<{
			id: string;
			firstName: string;
			lastName: string;
			userEmail: string;
			ccEmail: string;
			createdAt: string;
		}>;
	}

	async putEmailAddress(data: {
		firstName: string;
		lastName: string;
		userEmail: string;
		ccEmail?: string;
	}): Promise<
		backendResponse<{
			id: string;
			firstName: string;
			lastName: string;
			userEmail: string;
			ccEmail: string;
			createdAt: string;
		}>
	> {
		return (await this.backend.put(`emailAddresses`, data)) as backendResponse<{
			id: string;
			firstName: string;
			lastName: string;
			userEmail: string;
			ccEmail: string;
			createdAt: string;
		}>;
	}

	async setTaskStatus(
		id: string,
		status: TaskStatus,
		as: string
	): Promise<backendResponse<Task>> {
		return (await this.backend.patch(
			`task/${id}`,
			{ status },
			{
				spoofAs: as,
			}
		)) as backendResponse<Task>;
	}

	async setTaskDeadline(
		id: string,
		date: TaskStatus,
		as: string
	): Promise<backendResponse<Task>> {
		return (await this.backend.patch(
			`task/${id}`,
			{ estimatedCompletionDate: date },
			{
				spoofAs: as,
			}
		)) as backendResponse<Task>;
	}

	async reRunMLSort(id: string): Promise<backendResponse<any>> {
		return (await this.backend.put(`admin/inbox`, {
			id,
		})) as backendResponse<any>;
	}

	async submitReview(review: {
		id: string;
		tasks_subtasks?: {
			id: string;
			title: string;
			state?: TaskStatus;
			result?: TaskResult;
			confirmed?: boolean;
		}[];
		new_inquiries?: {
			propertyId: string;
			inquiry: string;
			userTypeBeingAsked: string;
			confirmed?: boolean;
		}[];
		inquiry_responses?: {
			id: string;
			propertyId: string;
			response: string;
			status: 'unresolved' | 'resolved' | 'deleted';
			confirmed?: boolean;
		}[];
	}): Promise<
		backendResponse<
			{
				date: Date;
				numberOfEmails: number;
				numberOfOpened: number;
				numberOfClicked: number;
				numberOfBounced: number;
				numberOfComplaint: number;
				numberOfUnsubscribed: number;
			}[]
		>
	> {
		return (await this.backend.patch(`admin/inbox`, review)) as backendResponse<
			{
				date: Date;
				numberOfEmails: number;
				numberOfOpened: number;
				numberOfClicked: number;
				numberOfBounced: number;
				numberOfComplaint: number;
				numberOfUnsubscribed: number;
			}[]
		>;
	}

	async getReport() {
		return (await this.backend.get(`emailReport`)) as backendResponse<any>;
	}
}

export interface Email {
	id: string;
	passthroughEmailId: string;
	messageId: string;
	email: string;
	types: 'to' | 'cc' | 'from'[];
	to: EAddress[];
	from: EAddress;
	cc: EAddress[];
	subject: string;
	attachments: attachment[];
	opens: event[];
	clicks: event[];
	sent: Date;
	delivered?: Date;
	bounced?: Date;
	chainIds: string[];
	propertyIds: string[];
	taskIds: string[];
	html?: string;
	properties?: Property[];
	tasks?: Task[];
	posableProperties?: Property[];
	posableTasks?: Task[];
	text?: string;
	mlDate?: Date;
	mlResults?: {
		tasks_subtasks?: {
			id: string;
			title: string;
			state?: TaskStatus;
			result?: TaskResult;
			confirmed?: boolean;
			confidence?: number;
			why?: string;
			task_confidence?: number;
			state_confidence?: number;
			property_confidence?: number;
		}[];
		new_inquiries?: {
			propertyId: string;
			inquiry: string;
			userTypeBeingAsked: string;
			confirmed?: boolean;
			confidence: number;
			why?: string;
			property_confidence?: number;
			v_confidence?: number;
		}[];
		inquiry_responses?: {
			id: string;
			propertyId: string;
			response: string;
			status: 'unresolved' | 'resolved' | 'deleted';
			confirmed?: boolean;
			confidence: number;
			why?: string;
			property_confidence?: number;
			v_confidence?: number;
		}[];
	};
}

export interface EAddress {
	name: string;
	ccAddress: string;
	userEmail: string;
}

export interface attachment {
	filename: string;
	contentType: string;
	key: string;
}

export interface event {
	ip?: string;
	timestamp: number;
	userAgent?: string;
	link?: string;
	source?: string;
}
