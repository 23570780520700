import { Component, OnInit, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthService } from 'src/app/services/auth.service';
import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { BuyerSellersBackendService } from '../services/buyer-sellers-backend.service';
import { TitleService } from '../services/title.service';
import { ChainsBackendService } from '../services/chains-backend.service';
import { SwPush } from '@angular/service-worker';
import { PeopleBackendService } from '../services/people-backend.service';
import { EmailBackendService } from '../services/email-backend.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit, OnDestroy {
	user: any = { attributes: { email: '' } };
	groups: string[] = [];
	get isAdmin() {
		return this.groups.includes('admin');
	}
	officialSearches: 'N' | 'Y' = 'N';

	ccEmails: {
		id: string;
		firstName: string;
		lastName: string;
		userEmail: string;
		ccEmail: string;
		createdAt: string;
	}[] = [];

	notificationsForm: FormGroup = this.fb.group({
		tasks: this.fb.group({
			unblocked: this.fb.group({
				ass: [undefined],
				track: [undefined],
				prop: [undefined],
				chain: [undefined],
			}),
			completed: this.fb.group({
				ass: [undefined],
				track: [undefined],
				prop: [undefined],
				chain: [undefined],
			}),
			updated: this.fb.group({
				ass: [undefined],
				track: [undefined],
				prop: [undefined],
				chain: [undefined],
			}),
			overdue: this.fb.group({
				ass: [undefined],
				track: [undefined],
				prop: [undefined],
				chain: [undefined],
			}),
			dueTomorrow: this.fb.group({
				ass: [undefined],
				track: [undefined],
				prop: [undefined],
				chain: [undefined],
			}),
		}),
		buyerSeller: this.fb.group({
			misBuyingProp: [undefined],
			misSellingProp: [undefined],
		}),
		prop: this.fb.group({
			misBuyer: [undefined],
			misSeller: [undefined],
			misAgent: [undefined],
			misSellerCon: [undefined],
			misBuyerCon: [undefined],
		}),
		chain: this.fb.group({
			complete: [undefined],
			exComp: [undefined],
		}),
	});

	get tasksForm(): FormGroup {
		return this.notificationsForm.get('tasks') as FormGroup;
	}

	get buyerSellerForm(): FormGroup {
		return this.notificationsForm.get('buyerSeller') as FormGroup;
	}

	get propForm(): FormGroup {
		return this.notificationsForm.get('prop') as FormGroup;
	}

	get chainsForm(): FormGroup {
		return this.notificationsForm.get('chain') as FormGroup;
	}

	get tasksUnblocked(): FormGroup {
		return this.tasksForm.get('unblocked') as FormGroup;
	}
	get tasksCompleted(): FormGroup {
		return this.tasksForm.get('completed') as FormGroup;
	}
	get tasksUpdated(): FormGroup {
		return this.tasksForm.get('updated') as FormGroup;
	}
	get tasksOverdue(): FormGroup {
		return this.tasksForm.get('overdue') as FormGroup;
	}
	get tasksDueTomorrow(): FormGroup {
		return this.tasksForm.get('dueTomorrow') as FormGroup;
	}

	ccEmail: string = '';

	emailAddressesDomains: string[] = [];

	constructor(
		public MediaMatcher: MediaMatcher,
		public AuthService: AuthService,
		public BuyerSellerBackend: BuyerSellersBackendService,
		public fb: FormBuilder,
		public titleService: TitleService,
		public chainBackend: ChainsBackendService,
		public peopleBackend: PeopleBackendService,
		public emailBackend: EmailBackendService,
		private swPush: SwPush,
		public clipboard: Clipboard,
		public snackbar: MatSnackBar
	) {}

	async ngOnInit() {
		this.titleService.title = 'Settings';
		this.user = await this.AuthService.getUser();
		this.groups =
			this.user?.signInUserSession?.accessToken?.payload?.['cognito:groups'];
		console.log(
			this.user?.signInUserSession?.accessToken?.payload?.['cognito:groups']
		);
		let savedTheme = localStorage.getItem('theme');
		if (!!savedTheme) this.theme = savedTheme as 'light-theme' | 'dark-theme';
		else
			this.theme =
				document.body.classList.contains('dark-theme') ||
				this.MediaMatcher.matchMedia('(prefers-color-scheme: dark)').matches
					? 'dark-theme'
					: 'light-theme';
		if (
			!document.body.classList.contains('dark-theme') &&
			this.theme === 'dark-theme'
		) {
			this.selectDarkTheme();
		}
		if (
			!document.body.classList.contains('light-theme') &&
			this.theme === 'light-theme'
		) {
			this.selectLightTheme();
		}
		this.switchThemes = (dark: boolean) => {
			if (dark) {
				this.selectDarkTheme();
			} else {
				this.selectLightTheme();
			}
		};
		this.MediaMatcher.matchMedia(
			'(prefers-color-scheme: dark)'
		).addEventListener('change', this.switchThemes);

		this.getNotificationPreferences();

		let sr = await this.peopleBackend.getOfficialSearch();
		this.officialSearches = sr.data ? 'Y' : 'N';

		let rDomains = await this.emailBackend.getEmailAddressesDomains();
		this.emailAddressesDomains = rDomains.data;

		let cEmailR = await this.emailBackend.putEmailAddress({
			firstName: this.user.attributes.given_name,
			lastName: this.user.attributes.family_name,
			userEmail: this.user.attributes.email,
		});
		this.currentCcEmail = cEmailR.data.ccEmail;

		await this.getEmails();
	}
	ngOnDestroy() {
		this.MediaMatcher.matchMedia(
			'(prefers-color-scheme: dark)'
		).removeEventListener('change', this.switchThemes);
	}

	loadingEmails = true;
	async getEmails() {
		this.loadingEmails = true;
		let ccEmailsR = await this.emailBackend.getEmailAddress();
		this.ccEmails = ccEmailsR.data;
		this.loadingEmails = false;
	}

	async saveOfficialSearch(value: 'Y' | 'N') {
		await this.peopleBackend.patchOfficialSearch(value == 'Y');
		this.officialSearches = value;
	}

	savingPrefs = false;
	async getNotificationPreferences() {
		this.savingPrefs = true;
		let prefs = await this.AuthService.getNotificationPreferences();
		//console.log(prefs);
		this.notificationsForm.patchValue(prefs);
		this.notificationsForm.markAsPristine();

		this.savingPrefs = false;
	}

	async saveNotificationPreferences() {
		this.savingPrefs = true;
		let prefs = this.notificationsForm.value;
		await this.AuthService.patchNotificationPreferences(prefs);
		await this.getNotificationPreferences();
		this.savingPrefs = false;
	}

	switchThemes: any;

	theme: 'light-theme' | 'dark-theme' | undefined | null = 'light-theme';

	manualThemeChange(dark: boolean) {
		if (dark) {
			this.selectDarkTheme(true);
		} else {
			this.selectLightTheme(true);
		}
	}

	public selectDarkTheme(save = false): void {
		document.body.classList.add('dark-theme');
		document.body.classList.remove('light-theme');
		this.theme = 'dark-theme';
		if (save) localStorage.setItem('theme', 'dark-theme');
	}

	public selectLightTheme(save = false): void {
		document.body.classList.remove('dark-theme');
		document.body.classList.add('light-theme');
		this.theme = 'light-theme';
		if (save) localStorage.setItem('theme', 'light-theme');
	}

	spoofUser(email: string) {
		this.AuthService.spoofAs = email;
	}

	testError() {
		throw new Error('Test Error');
	}

	discounts: string[] = [];
	async createDiscountCodes(_n: string, preCode: string, _discount: string) {
		let discount = parseInt(_discount);
		let n = parseInt(_n);
		preCode = preCode.toUpperCase();

		for (let index = 0; index < n; index++) {
			let code = await this.BuyerSellerBackend.putDiscount({
				preCode: preCode,
				percentOff: discount,
			});
			this.discounts.push(code.data.code);
		}
	}

	deleteChainId: string | undefined;
	async deleteChain() {
		if (this.deleteChainId && this.deleteChainId.length > 5)
			await this.chainBackend.delete(this.deleteChainId);
		this.deleteChainId = '';
	}

	async subPush() {
		let endPoint = await this.swPush
			.requestSubscription({
				serverPublicKey:
					'BC7AcpKRDEU3K45ASPG9SnU-TeeqKyPMzkImno1P7kfk4qoyYtBDIJFAB34f8-f4zRnrQN7h6l86LUV9Fg3diUw',
			})
			.catch((err) =>
				console.error('Could not subscribe to notifications', err)
			);

		if (endPoint) {
			await this.peopleBackend.addPushEndpoint(endPoint);
		}
	}

	async getCcEmail(firstName: string, lastName: string, userEmail: string) {
		let r = await this.emailBackend.putEmailAddress({
			firstName,
			lastName,
			userEmail,
		});
		this.ccEmail = r.data.ccEmail;
	}

	currentCcEmail: string = '';

	async newCcEmail(newCcEmail: string, domain: string) {
		//check for @ in the newCcEmail and error if so
		if (newCcEmail.includes('@') || !domain || domain.length < 1) {
			this.snackbar.open(
				'Please enter a valid email address, type your name on the left and select your domain on the right.',
				'Dismiss',
				{
					duration: 3000,
					verticalPosition: 'top',
				}
			);
			return;
		}

		let _newCcEmail = `${newCcEmail}@${domain}`;
		let r = await this.emailBackend.putEmailAddress({
			firstName: this.user.attributes.given_name,
			lastName: this.user.attributes.family_name,
			userEmail: this.user.attributes.email,
			ccEmail: _newCcEmail,
		});
		this.currentCcEmail = r.data.ccEmail;

		let ccEmailsR = await this.emailBackend.getEmailAddress();
		this.ccEmails = ccEmailsR.data;
	}

	async copyCCEmail() {
		this.clipboard.copy(this.ccEmail);
		this.snackbar.open(`Email ${this.ccEmail} copied to clipboard`, 'Dismiss', {
			duration: 3000,
			verticalPosition: 'top',
		});
	}

	async copyCurrentCCEmail() {
		this.clipboard.copy(this.currentCcEmail);
		this.snackbar.open(
			`Email ${this.currentCcEmail} copied to clipboard`,
			'Dismiss',
			{
				duration: 3000,
				verticalPosition: 'top',
			}
		);
	}

	async makeDefaultEmail(id: string) {
		this.loadingEmails = true;
		let r = await this.emailBackend.setEmailAddressDefault({ id });
		this.currentCcEmail = r.data.ccEmail;

		await this.getEmails();
	}

	async copyToClipBoard(email: string) {
		this.clipboard.copy(email);
		this.snackbar.open(`Email ${email} copied to clipboard`, 'Dismiss', {
			duration: 3000,
			verticalPosition: 'top',
		});
	}
}
